class Header {
  constructor() {
    this.header = document.querySelector('.iz-header');

    if (this.header) {
      this.bindEvents();

      window.addEventListener('resize', () => {
        this.bindEvents();
      });
    }
  }

  bindEvents() {
    let height = this.header.offsetHeight;
    let root = document.documentElement;
    root.style.setProperty('--theme-header-height', height + "px");
  }
}

export default Header;
