
import Helper from './Helper';
class ReadingProgress {
  constructor() {
    this.readingProgress = document.querySelector('.js-reading-progress');
    if(this.readingProgress){
      this.bindScrollEvent(this.readingProgress);
    }
  }

  bindScrollEvent(readingProgress){
    window.addEventListener('scroll', function(e) {
      let s = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
      let body = document.body;
      let html = document.documentElement;
      let d = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      let c = window.innerHeight;
      let position = (s / (d - c)) * 100;
      readingProgress.setAttribute('style', 'width: ' + position + '%');
    });

  }

}

export default ReadingProgress;
