import Helper from './Helper';

class LightboxImage {
  constructor(config = {}) {
    const {} = config;

    // create element and append at bottom
    const lightbox = document.createElement('div')
    const lightboxButton = document.createElement('button')
    const lightboxContainer = document.createElement('div')
    const lightboxInner = document.createElement('div')

    lightbox.id = 'lightbox';
    lightbox.className = 'iz-lightbox'

    lightboxButton.className = "lc-button--close iz-lightbox__button--close";

    lightboxContainer.id = 'lightbox__container';
    lightboxContainer.className = "lc-component iz-lightbox__container";

    lightboxInner.id = 'lightbox__inner';
    lightboxInner.className = "lc-component__inner iz-lightbox__inner";

    document.body.appendChild(lightbox);
    lightbox.append(lightboxButton);
    lightbox.append(lightboxContainer);
    lightboxContainer.append(lightboxInner);

    const images = document.querySelectorAll('.lc-image-lightbox--img')

    images.forEach(image => {
      image.addEventListener('click', e => {
        lightbox.classList.add('active')

        const img = document.createElement('img')
        const caption = image.closest('.lc-image__image-container').nextElementSibling.cloneNode(true);

        img.src = image.src

        while (lightboxInner.firstChild) {
          lightboxInner.removeChild(lightboxInner.firstChild)
        }

        lightboxInner.appendChild(img)
        lightboxInner.appendChild(caption);
      })
    })

    lightbox.addEventListener('click', e => {
      lightbox.classList.remove('active')
    })

  }
}

export default LightboxImage;
