import Swiper from "swiper";
import {Navigation, Pagination, Autoplay} from "swiper/modules";
import LightboxImage from "./Classes/Lightbox";
import ReadingProgress from "./Classes/ReadingProgress";
import Header from "./Classes/Header";

window.addEventListener("page_event_load", () => {
  new LightboxImage();

  new Swiper('.lc-swiper .swiper', {
    modules: [Navigation, Pagination, Autoplay],
    loop: true,
    slidesPerView: 1,
    speed: 900,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    }
  });

  new ReadingProgress();

  new Header();
});

window.addEventListener('note_event_load', evt => {
  // console.log("note loaded event");
  document.body.classList.add("note-open");

});


window.addEventListener('note_unloaded', evt => {
  //console.log("note unloaded event");
  document.body.classList.remove("note-open");
});
